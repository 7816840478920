// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css';
@import '~bootstrap-icons/font/bootstrap-icons.css';
@import "~datatables.net-bs5/css/dataTables.bootstrap5.css";
@import '~datatables.net-responsive-bs5/css/responsive.bootstrap5.css';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
